import React from 'react';
import { Link } from 'react-router-dom';
import ButtonIcon from './ButtonIcon';

function Address() {
  return (
    <div>
      <h1 className="text-primary font-medium text-xl">Pulih@thePeak</h1>
      <p className="text-secondary font-medium lg:text-sm text-xs mt-3">Jl. Brawijaya Raya No.15, RT.3/RW.3, Pulo, Kec. Kby. Baru, Kota Jakarta</p>
      <p className="text-secondary font-medium lg:text-sm text-xs">Selatan, Daerah Khusus Ibukota Jakarta 12160</p>
      <p className="text-secondary font-medium lg:text-sm text-xs mt-3">Jl Wolter Mongonsidi No. 73 Zm 23 B, Kebayoran Baru, 12180.</p>
      <p className="text-secondary font-medium lg:text-sm text-xs mt-3">083128769871</p>
    </div>
  );
}

function SocialMedia() {
  return (
    <div className="flex gap-6 w-full lg:w-max">
      <Link to="https://twitter.com/pulihatthepeak" target="_blank">
        <ButtonIcon>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_751_7921)">
              <path d="M32.9412 6.18237C31.7432 6.71235 30.4727 7.06051 29.1719 7.21534C30.5425 6.39452 31.568 5.10346 32.0573 3.58268C30.77 4.34877 29.3587 4.88567 27.8894 5.17609C26.9013 4.11891 25.5917 3.41779 24.164 3.18172C22.7364 2.94564 21.2707 3.18785 19.995 3.87068C18.7192 4.55351 17.7047 5.6387 17.1093 6.95755C16.5139 8.27641 16.3709 9.75502 16.7025 11.1635C14.0918 11.0329 11.5377 10.3546 9.20606 9.17273C6.87446 7.99084 4.81752 6.33177 3.16879 4.30325C2.58526 5.30552 2.27861 6.44489 2.28025 7.60466C2.28025 9.88096 3.44038 11.8919 5.20019 13.0693C4.15783 13.0365 3.13841 12.755 2.22688 12.2483V12.3284C2.22683 13.8446 2.75126 15.3143 3.71123 16.488C4.67119 17.6617 6.00759 18.4672 7.49375 18.7679C6.52616 19.0304 5.51148 19.0691 4.52672 18.881C4.94575 20.1861 5.76253 21.3275 6.86267 22.1453C7.9628 22.9631 9.29117 23.4163 10.6617 23.4414C9.29965 24.5112 7.74004 25.302 6.0721 25.7685C4.40416 26.2351 2.66061 26.3682 0.941162 26.1604C3.94229 28.0903 7.43583 29.115 11.004 29.1117C23.0824 29.1117 29.6853 19.107 29.6853 10.4304C29.6853 10.1478 29.679 9.86212 29.6664 9.58111C30.9512 8.65232 32.0601 7.50036 32.9412 6.18237Z" fill="#953106" />
            </g>
            <defs>
              <clipPath id="clip0_751_7921">
                <rect width="32" height="32" fill="white" transform="translate(0.941162 0.102539)" />
              </clipPath>
            </defs>
          </svg>
        </ButtonIcon>
      </Link>
      <Link to="https://www.instagram.com/pulihatp/" target="_blank">
        <ButtonIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
            <path d="M11.3411 2.76904H22.5411C26.8078 2.76904 30.2745 6.23571 30.2745 10.5024V21.7024C30.2745 23.7534 29.4597 25.7204 28.0094 27.1707C26.5591 28.6209 24.5921 29.4357 22.5411 29.4357H11.3411C7.07445 29.4357 3.60779 25.969 3.60779 21.7024V10.5024C3.60779 8.45137 4.42255 6.48436 5.87283 5.03408C7.32311 3.5838 9.29011 2.76904 11.3411 2.76904ZM11.0745 5.43571C9.80141 5.43571 8.58052 5.94142 7.68034 6.8416C6.78017 7.74177 6.27445 8.96267 6.27445 10.2357V21.969C6.27445 24.6224 8.42112 26.769 11.0745 26.769H22.8078C24.0808 26.769 25.3017 26.2633 26.2019 25.3632C27.1021 24.463 27.6078 23.2421 27.6078 21.969V10.2357C27.6078 7.58238 25.4611 5.43571 22.8078 5.43571H11.0745ZM23.9411 7.43571C24.3831 7.43571 24.8071 7.6113 25.1196 7.92386C25.4322 8.23643 25.6078 8.66035 25.6078 9.10238C25.6078 9.5444 25.4322 9.96833 25.1196 10.2809C24.8071 10.5934 24.3831 10.769 23.9411 10.769C23.4991 10.769 23.0752 10.5934 22.7626 10.2809C22.45 9.96833 22.2745 9.5444 22.2745 9.10238C22.2745 8.66035 22.45 8.23643 22.7626 7.92386C23.0752 7.6113 23.4991 7.43571 23.9411 7.43571ZM16.9411 9.43571C18.7092 9.43571 20.4049 10.1381 21.6552 11.3883C22.9054 12.6386 23.6078 14.3343 23.6078 16.1024C23.6078 17.8705 22.9054 19.5662 21.6552 20.8164C20.4049 22.0667 18.7092 22.769 16.9411 22.769C15.173 22.769 13.4773 22.0667 12.2271 20.8164C10.9768 19.5662 10.2745 17.8705 10.2745 16.1024C10.2745 14.3343 10.9768 12.6386 12.2271 11.3883C13.4773 10.1381 15.173 9.43571 16.9411 9.43571ZM16.9411 12.1024C15.8803 12.1024 14.8628 12.5238 14.1127 13.2739C13.3625 14.0241 12.9411 15.0415 12.9411 16.1024C12.9411 17.1632 13.3625 18.1807 14.1127 18.9308C14.8628 19.6809 15.8803 20.1024 16.9411 20.1024C18.002 20.1024 19.0194 19.6809 19.7695 18.9308C20.5197 18.1807 20.9411 17.1632 20.9411 16.1024C20.9411 15.0415 20.5197 14.0241 19.7695 13.2739C19.0194 12.5238 18.002 12.1024 16.9411 12.1024Z" fill="#953106" />
          </svg>
        </ButtonIcon>
      </Link>
      <Link to="https://www.facebook.com/profile.php?id=100072045228126" target="_blank">
        <ButtonIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
            <path d="M30.2745 16.1024C30.2745 8.74238 24.3011 2.76904 16.9411 2.76904C9.58112 2.76904 3.60779 8.74238 3.60779 16.1024C3.60779 22.5557 8.19445 27.929 14.2745 29.169V20.1024H11.6078V16.1024H14.2745V12.769C14.2745 10.1957 16.3678 8.10238 18.9411 8.10238H22.2745V12.1024H19.6078C18.8745 12.1024 18.2745 12.7024 18.2745 13.4357V16.1024H22.2745V20.1024H18.2745V29.369C25.0078 28.7024 30.2745 23.0224 30.2745 16.1024Z" fill="#953106" />
          </svg>
        </ButtonIcon>
      </Link>
      <Link to="https://api.whatsapp.com/send/?phone=6285103209515" target="_blank">
        <ButtonIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
            <path d="M26.3411 6.64912C25.1187 5.41436 23.6627 4.43535 22.058 3.76919C20.4532 3.10303 18.7319 2.76307 16.9944 2.76912C9.71444 2.76912 3.78111 8.70246 3.78111 15.9825C3.78111 18.3158 4.39444 20.5825 5.54111 22.5825L3.67444 29.4358L10.6744 27.5958C12.6078 28.6491 14.7811 29.2091 16.9944 29.2091C24.2744 29.2091 30.2078 23.2758 30.2078 15.9958C30.2078 12.4625 28.8344 9.14246 26.3411 6.64912ZM16.9944 26.9691C15.0211 26.9691 13.0878 26.4358 11.3944 25.4358L10.9944 25.1958L6.83444 26.2891L7.9411 22.2358L7.67444 21.8225C6.5781 20.0717 5.99596 18.0481 5.99444 15.9825C5.99444 9.92912 10.9278 4.99579 16.9811 4.99579C19.9144 4.99579 22.6744 6.14246 24.7411 8.22246C25.7644 9.24107 26.5754 10.4527 27.127 11.787C27.6786 13.1214 27.9598 14.5519 27.9544 15.9958C27.9811 22.0491 23.0478 26.9691 16.9944 26.9691ZM23.0211 18.7558C22.6878 18.5958 21.0611 17.7958 20.7678 17.6758C20.4611 17.5691 20.2478 17.5158 20.0211 17.8358C19.7944 18.1691 19.1678 18.9158 18.9811 19.1291C18.7944 19.3558 18.5944 19.3825 18.2611 19.2091C17.9278 19.0491 16.8611 18.6891 15.6078 17.5691C14.6211 16.6891 13.9678 15.6091 13.7678 15.2758C13.5811 14.9425 13.7411 14.7691 13.9144 14.5958C14.0611 14.4491 14.2478 14.2091 14.4078 14.0225C14.5678 13.8358 14.6344 13.6891 14.7411 13.4758C14.8478 13.2491 14.7944 13.0625 14.7144 12.9025C14.6344 12.7425 13.9678 11.1158 13.7011 10.4491C13.4344 9.80912 13.1544 9.88912 12.9544 9.87579H12.3144C12.0878 9.87579 11.7411 9.95579 11.4344 10.2891C11.1411 10.6225 10.2878 11.4225 10.2878 13.0491C10.2878 14.6758 11.4744 16.2491 11.6344 16.4625C11.7944 16.6891 13.9678 20.0225 17.2744 21.4491C18.0611 21.7958 18.6744 21.9958 19.1544 22.1425C19.9411 22.3958 20.6611 22.3558 21.2344 22.2758C21.8744 22.1825 23.1944 21.4758 23.4611 20.7025C23.7411 19.9291 23.7411 19.2758 23.6478 19.1291C23.5544 18.9825 23.3544 18.9158 23.0211 18.7558Z" fill="#953106" />
          </svg>
        </ButtonIcon>
      </Link>
    </div>
  );
}

function Footer() {
  return (
    <div className="bg-pink mt-12">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between lg:items-center py-6 px-8 gap-8">
        <Address />
        <SocialMedia />
      </div>
    </div>
  );
}

export default Footer;
