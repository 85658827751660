import Merchandise from './merchandise.png';
import Product1 from './product1.png';
import Product2 from './product2.png';
import Product3 from './product3.png';
import CommingSoon from './comming.png';

const MerchandiseImages = {
  Merchandise,
  Product1,
  Product2,
  Product3,
  CommingSoon,
};

export default MerchandiseImages;
